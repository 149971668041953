const defaultState = {
  loggedIn: false,
  loading: true,
  userDataLoaded: false,
  propertyId: "",
  customContentLoaded: false,
  customContentPropertyId: "",
  sharedContentLoaded: false,
  customCategories: {},
  sharedContentProfileId: "",
  sharedContentLoadedId: "",
  singlePropertyDataLoaded: false,
  linkedPropertiesLoaded: false,
  linkedPropertyManagersLoaded: false,
  linkedManagementCoLoaded: true,
  attachContentLoaded: "",
  sharedContentProfilesLoaded: "",
  subscriptionData: {},
  subscriptionDataLoaded: false,
  referralData: {},
  referralDataLoaded: false,
  analyticsData: {},
  analyticsDataLoaded: false,
  dataLoadPercent: "",
  fileLoadStatus: "",
  responseData: {},
  conversationDataLoaded: false,
  messageData: {},
  messageDataLoaded: false,
  conversationsDataRefreshing: false,
  conversationMessagesRefreshing: false,
  user: {},
};

const userReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "SET_RESPONSE_DATA":
      return {
        ...state,
        loggedIn: false,
        loading: false,
        responseData: { ...action.payload },
      };
    case "SET_USER":
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
        loggedIn: true,
        loading: false,
      };
    case "SET_USER_DATA":
      return {
        ...state,
        user: {
          ...state.user,
          userData: action.payload,
        },
        userDataLoaded: true,
      };
    case "SET_CUSTOM_CONTENT":
      return {
        ...state,
        user: {
          ...state.user,
          linkedCustomContent: action.payload,
        },
        customContentLoaded: true,
        customContentPropertyId: action.propertyId,
      };
    case "SET_CUSTOM_CONTENT_LOADED":
      return {
        ...state,
        customContentLoaded: action.payload,
        customContentPropertyId: action.propertyId,
      };

    case "SET_CUSTOM_CATEGORIES":
      return {
        ...state,
        user: {
          ...state.user,
          customCategories: action.payload,
        },
      };

    case "SET_SHARED_PROFILES":
      return {
        ...state,
        user: {
          ...state.user,
          sharedContentProfiles: action.payload,
        },
        sharedContentProfilesLoaded: action.itemUniqueId,
      };
    case "SET_SHARED_PROFILES_LOADED":
      return {
        ...state,
        sharedContentProfilesLoaded: action.payload,
      };

    case "SET_SHARED_CONTENT":
      return {
        ...state,
        user: {
          ...state.user,
          sharedCustomContent: action.payload,
        },
        sharedContentLoaded: true,
        sharedContentProfileId: action.profileId,
        sharedContentLoadedId: action.profileId,
      };
    case "SET_SHARED_CONTENT_LOADED":
      return {
        ...state,
        sharedContentLoaded: action.payload,
        sharedContentLoadedId: action.profileId,
      };

    case "SET_ATTACH_CONTENT_LOADED":
      return {
        ...state,
        attachContentLoaded: action.payload,
      };

    case "SET_PROPERTY_ID":
      return {
        ...state,
        propertyId: action.propertyId,
      };

    case "SET_LINKED_PROPERTIES_DATA":
      return {
        ...state,
        user: {
          ...state.user,
          linkedProperties: action.payload,
        },
        linkedPropertiesLoaded: true,
      };
    case "SET_LINKED_PROPERTIES_LOADED":
      return {
        ...state,
        linkedPropertiesLoaded: action.payload,
      };
    case "SET_SINGLE_PROPERTY_DATA": {
      const index = state.user.linkedProperties.findIndex(
        (item) => item._id === action.propertyId
      );
      const newArray = [...state.user.linkedProperties];
      newArray[index] = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          linkedProperties: newArray,
        },
        singlePropertyDataLoaded: true,
      };
    }
    case "SINGLE_PROPERTY_DATA_LOADED":
      return {
        ...state,
        singlePropertyDataLoaded: action.payload,
      };
    case "SET_LINKED_MANAGERS_DATA":
      return {
        ...state,
        user: {
          ...state.user,
          linkedPropertyManagers: action.payload,
        },
        linkedPropertyManagersLoaded: true,
      };
    case "SET_LINKED_MANAGERS_LOADED":
      return {
        ...state,
        linkedPropertyManagersLoaded: action.payload,
      };

    case "SET_LINKED_MANAGEMENTCO_DATA":
      return {
        ...state,
        user: {
          ...state.user,
          linkedManagementCo: action.payload,
        },
        linkedManagementCoLoaded: true,
      };
    case "SET_LINKED_MANAGEMENTCO_LOADED":
      return {
        ...state,
        linkedManagementCoLoaded: action.payload,
      };

    case "SET_SUBSCRIPTION_DATA":
      return {
        ...state,
        user: {
          ...state.user,
          subscriptionData: action.payload,
        },
        subscriptionDataLoaded: true,
      };
    case "SUBSCRIPTION_DATA_LOADED":
      return {
        ...state,
        subscriptionDataLoaded: action.payload,
      };

    case "SET_REFERRAL_DATA":
      return {
        ...state,
        user: {
          ...state.user,
          referralData: action.payload,
        },
        referralDataLoaded: true,
      };
    case "SET_REFERRAL_DATA_LOADED":
      return {
        ...state,
        referralDataLoaded: action.payload,
      };

    case "SET_ANALYTICS_DATA":
      return {
        ...state,
        user: {
          ...state.user,
          analyticsData: action.payload,
        },
        analyticsDataLoaded: true,
      };
    case "SET_ANALYTICS_DATA_LOADED":
      return {
        ...state,
        analyticsDataLoaded: action.payload,
      };

    case "SET_CONVERSATION_DATA":
      return {
        ...state,
        user: {
          ...state.user,
          conversationData: action.payload,
        },
        conversationDataLoaded: true,
        conversationDataRefreshing: false,
      };
    case "SET_CONVERSATION_DATA_LOADED":
      return {
        ...state,
        conversationDataLoaded: action.payload,
      };

    case "SET_MESSAGE_DATA":
      return {
        ...state,
        user: {
          ...state.user,
          messageData: action.payload,
        },
        messageDataLoaded: true,
        conversationMessagesRefreshing: false,
      };
    case "SET_MESSAGE_DATA_LOADED":
      return {
        ...state,
        messageDataLoaded: action.payload,
      };

    case "SET_DATA_LOAD_PERCENT":
      return {
        ...state,
        dataLoadPercent: action.payload,
      };

    case "SET_FILE_LOAD_STATUS":
      return {
        ...state,
        fileLoadStatus: action.payload,
      };

    case "LOG_OUT":
      return {
        loggedIn: false,
        loading: false,
        userDataLoaded: false,
        propertyId: "",
        customContentLoaded: false,
        customContentPropertyId: "",
        sharedContentLoaded: false,
        sharedContentProfileId: "",
        singlePropertyDataLoaded: false,
        linkedPropertiesLoaded: false,
        linkedPropertyManagersLoaded: false,
        linkedManagementCoLoaded: true,
        conversationDataLoaded: true,
        messageDataLoaded: true,
        responseData: {},
        user: {},
      };

    case "SET_SYSTEM_CONTENT":
      return {
        // user: { ...action.payload }

        ...state,
        user: {
          ...state.user,
          systemContent: action.payload,
        },
      };
    default:
      return state;
  }
};

export default userReducer;
