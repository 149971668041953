import { dataApiRoot, apiSecretToken } from "../constants.js";
import { setCustomCategories } from "../redux/actions/userActions.js";

export const fetchCustomContentCategories = async (dispatch, userId) => {
  if (userId) {
    const fetchCustomContentCategoriesData = async function (cursor) {
      let apiUrl = `${dataApiRoot}customcontentcategory?constraints=[{%22key%22:%22linkeduser%22,%22constraint_type%22:%22equals%22,%22value%22:%22${userId}%22}]&sort_field=listrank&cursor=${cursor}`;
      var apiResults = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${apiSecretToken}`,
        },
      }).then((resp) => {
        return resp.json();
      });
      return apiResults;
    };

    const fetchAllCustomContentCategoriesData = async function (cursor = 0) {
      const data = await fetchCustomContentCategoriesData(cursor);
      const { remaining, results } = data.response;
      //  console.log("Retreiving data from API for cursor position: " + cursor);
      if (remaining > 0) {
        cursor += results.length;
        return results.concat(
          await fetchAllCustomContentCategoriesData(cursor)
        );
      } else {
        return results;
      }
    };

    (async () => {
      const allCustomContentCategoriesData =
        await fetchAllCustomContentCategoriesData();

      dispatch(setCustomCategories(allCustomContentCategoriesData));
    })();
  }
};
